import { useTranslation } from 'react-i18next';
import { IconHelp } from '../../../../assets/Icons';
import { Text, Box, Flex, useColorMode } from '@chakra-ui/react';

const IncomesInfoBox = ({ projectSelected }) => {
	const { t } = useTranslation();
	const { colorMode } = useColorMode();

	return (
		<Box
			bg={
				colorMode === 'dark'
					? 'transparencyWhite.100'
					: 'transparencyBlack.50'
			}
			borderRadius='15px'
			py='15px'
			px='10px'
		>
			<Flex fontWeight='700' justifyContent='space-between' pb='10px'>
				<Flex align='center' gap={1}>
					<Text fontSize='14'>
						{t(
							'projectDetails.offerDetails.projectIncomesInfo.incomesInfoBox.roi',
						)}{' '}
					</Text>
					<Text variant='gray' fontWeight='600'>
						(ROI)
					</Text>
					<Box
						color={
							colorMode === 'dark'
								? 'transparencyWhite.500'
								: 'transparencyBlack.50'
						}
					>
						<IconHelp size='13px' color='gray' />
					</Box>
				</Flex>
				<Text fontSize='16'>{projectSelected?.roi}</Text>
			</Flex>
			<Box
				bg={
					colorMode === 'dark'
						? 'transparencyWhite.100'
						: 'transparencyBlack.100'
				}
				borderRadius='10px'
				p='10px'
			>
				<Flex justifyContent='space-between'>
					<Flex align='center' gap={1}>
						<Text fontSize='10'>
							{t(
								'projectDetails.offerDetails.projectIncomesInfo.incomesInfoBox.monthlyRentalIncome',
							)}
						</Text>
						<Box>
							<IconHelp size='12px' color='gray' />
						</Box>
					</Flex>
					<Text fontSize='10px'>
						{!projectSelected?.appreciation
							? '-'
							: `${projectSelected?.appreciation} %`}
					</Text>
				</Flex>
			</Box>
		</Box>
	);
};

export default IncomesInfoBox;
