import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePickerComponent.css';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useState } from 'react';
import { IconArrowDown } from '../../assets/Icons';
import { formatDateLongMonth } from '../../utils/utils';

const DatePickerComponent = ({
	name,
	setInfo,
	jsonData,
	incorrectData,
	minDate = new Date(),
}) => {
	const [selectedDate, setSelectedDate] = useState('');

	const handleDateChange = date => {
		setSelectedDate(date);

		const dateRange = {
			from: null,
			to: null,
			day: date,
		};

		setInfo(prevInfo => ({
			...prevInfo,
			data: {
				...prevInfo.data,
				[name]: jsonData ? dateRange : date.toISOString(),
			},
		}));

		delete incorrectData[incorrectData.indexOf(name)];
	};

	const formattedDate = selectedDate ? formatDateLongMonth(selectedDate) : '';

	return (
		<DatePicker
			selected={selectedDate}
			onChange={handleDateChange}
			customInput={
				<InputGroup>
					<InputRightElement>
						<IconArrowDown />
					</InputRightElement>
					<Input
						name={name}
						placeholder='Select date'
						value={formattedDate}
						readOnly
						cursor='pointer'
						outline={
							incorrectData?.includes('financingStart')
								? '1px solid red'
								: 'none'
						}
					/>
				</InputGroup>
			}
			minDate={minDate}
			wrapperClassName='datepicker-wrapper'
			calendarClassName='datepicker-calendar'
			inputClassName='datepicker-input'
			showPopperArrow={false}
			dateFormat='MM/dd/yyyy'
		/>
	);
};

export default DatePickerComponent;
