import {
	Button,
	Divider,
	Flex,
	GridItem,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
} from '@chakra-ui/react';
import { currencyFormatter, multiplicationCalc } from '../../../utils/utils';
import {
	IconView,
	IconEdit,
	IconDots,
	IconPublish,
} from '../../../assets/Icons';
import { useNavigate } from 'react-router-dom';
import { PublicRoutes } from '../../../routes/Routes';

const PublishedInfoComponent = ({ isMobile, colorBorder, data, last }) => {
	const navigate = useNavigate();
	const { isDraft, pricePerToken, quantityOfTokens, title } = data;
	return (
		<>
			<GridItem py={isMobile ? '15px' : '0'}>
				<Text
					fontSize={isMobile ? '12px' : '14px'}
					fontWeight={isMobile ? '600' : '500'}
				>
					{title}
				</Text>
			</GridItem>
			<Text
				px='5px'
				variant='gray'
				fontSize={isMobile ? '10px' : '12px'}
				fontWeight='400'
			>
				{currencyFormatter(
					multiplicationCalc(quantityOfTokens, pricePerToken),
				)}
			</Text>
			<Text
				px='5px'
				variant='gray'
				fontSize={isMobile ? '10px' : '12px'}
				fontWeight='400'
			>
				{isDraft ? 'Draft' : 'Published'}
			</Text>

			{!isMobile && (
				<Flex
					w='100%'
					justifyContent='center'
					align='center'
					p='6px'
					gap='10px'
				>
					<Button
						variant='secondary'
						fontWeight='400'
						fontSize='13px'
						p='10px'
						gap='5px'
					>
						<IconEdit />
						Edit
					</Button>
					<Button
						variant='secondary'
						fontWeight='400'
						fontSize='13px'
						p='10px'
						gap='5px'
						onClick={() =>
							navigate(
								`/${PublicRoutes.Project}/${data.projectId}`,
							)
						}
					>
						<IconView />
						View
					</Button>

					<Button
						variant='main'
						fontWeight='400'
						fontSize='13px'
						p='10px'
						gap='5px'
						isDisabled={!isDraft}
					>
						<IconPublish />
						Publish
					</Button>
				</Flex>
			)}
			{isMobile && (
				<GridItem colSpan={isMobile ? 0 : 2}>
					<Menu>
						<MenuButton
							as={IconDots}
							boxSize='14px'
							cursor='pointer'
							transition='transform 0.2s'
							_hover={{ transform: 'scale(1.2)' }}
							_active={{ transform: 'scale(1)' }}
						/>
						<MenuList>
							<MenuItem gap='5px'>
								<IconView />
								Edit
							</MenuItem>
							<MenuItem gap='5px'>
								<IconView />
								View
							</MenuItem>
							<MenuItem gap='5px' isDisabled={!isDraft}>
								<IconPublish />
								Publish
							</MenuItem>
						</MenuList>
					</Menu>
				</GridItem>
			)}
			{!last && (
				<Divider
					gridColumn={isMobile ? 'span 5' : 'span 6'}
					borderColor={colorBorder}
				/>
			)}
		</>
	);
};

export default PublishedInfoComponent;
