import { Text, Box, Flex, Progress, useColorMode } from '@chakra-ui/react';
import {
	propertyPercentage,
	propertyAvariable,
	currencyFormatter,
} from '../../../../utils/utils';
import IncomesInfoBox from './IncomesInfoBox';
import { useTranslation } from 'react-i18next';

const ProjectIncomesInfo = ({ projectSelected }) => {
	const { t } = useTranslation();
	const { colorMode } = useColorMode();
	return (
		<Box
			minWidth='100%'
			border='0.5px solid'
			borderRadius='12px'
			borderColor={
				colorMode === 'dark'
					? 'transparencyWhite.100'
					: 'transparencyBlack.100'
			}
			px='10px'
			py='15px'
			my='10px'
		>
			<Text variant={'smallColored'} textAlign='center' pb='10px'>
				{t(
					'projectDetails.offerDetails.projectIncomesInfo.totalRaised',
				)}
			</Text>
			<Flex
				justifyContent='center'
				alignItems='center'
				gap='10px'
				pb='10px'
			>
				<Text variant='colored' fontSize='20' fontWeight='700'>
					{!projectSelected?.funded
						? '-'
						: currencyFormatter(projectSelected?.funded)}
				</Text>
				<Text variant='gray' fontSize='14' fontWeight='400'>
					{t('projectDetails.offerDetails.projectIncomesInfo.of')}
				</Text>
				<Text fontSize='20' fontWeight='700'>
					{!projectSelected?.totalPrize
						? '-'
						: currencyFormatter(projectSelected?.totalPrize)}
				</Text>
			</Flex>
			<Progress value={propertyPercentage(projectSelected)} h='8px' />
			<Flex
				fontSize='10'
				fontWeight='400'
				justifyContent='space-between'
				p='10px'
				pb='15px'
			>
				<Flex gap='1'>
					<Text variant='colored' fontSize='inherit'>
						{!projectSelected
							? '-'
							: `${propertyPercentage(projectSelected)} %`}
					</Text>
					<Text variant='gray' fontSize='inherit'>
						{t(
							'projectDetails.offerDetails.projectIncomesInfo.funded',
						)}
					</Text>
				</Flex>
				<Flex gap='1'>
					<Text variant='colored' fontSize='inherit'>
						{!projectSelected?.tokensPrize
							? '-'
							: currencyFormatter(projectSelected?.tokensPrize)}
					</Text>
					<Text variant='gray' fontSize='inherit'>
						{t(
							'projectDetails.offerDetails.projectIncomesInfo.minTicketPrice',
						)}
					</Text>
				</Flex>
				<Flex gap='1'>
					<Text variant='colored' fontSize='inherit'>
						{!projectSelected
							? '-'
							: currencyFormatter(
									propertyAvariable(projectSelected),
								)}
					</Text>
					<Text variant='gray' fontSize='inherit'>
						{t(
							'projectDetails.offerDetails.projectIncomesInfo.avariable',
						)}
					</Text>
				</Flex>
			</Flex>
			<IncomesInfoBox projectSelected={projectSelected} />
		</Box>
	);
};

export default ProjectIncomesInfo;
