import { blockedTemporaryEmailDomains } from '../../data/BlacklistTemporaryEmailDomains.js';

/**
 * @name validateIsSeveralEmptyValues
 * @description Check values are not empty
 * @param {Array<string>} values
 * @returns {boolean}
 * @example validateIsSeveralEmptyValues(["Hello", "World"]) => true
 * @example validateIsSeveralEmptyValues(["Hello", "        "]) => false
 */
export const validateIsSeveralEmptyValues = values => {
	values.forEach(value => {
		if (value.toString().trim() === '') return false;
	});

	return true;
};

/**
 * @name validateIsOneEmptyValue
 * @description Check value are not empty
 * @param {String} value
 * @param {String} fieldname
 * @returns {boolean}
 * @example validateIsOneEmptyValue("Hello", "name") => true
 * @example validateIsOneEmptyValue("        ", "name") => `name no puede estar vacío`
 *
 */
export const validateIsOneEmptyValue = value => {
	if (value.toString().trim() === '') return false;

	return true;
};

/**
 * @name validateIsValidEmail
 * @description Validates an email
 * @param {String} email
 * @returns {Boolean}
 * @example validateIsValidEmail("example@email.com") => true
 * @example validateIsValidEmail("exampleemail.com") => false
 */
export const validateIsValidEmail = email => {
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	return emailRegex.test(email);
};

/**
 * @name validateIsValidEmailDomain
 * @description Validates an email domain
 * @param {String} email
 * @returns {Boolean}
 */
export const validateIsValidEmailDomain = email => {
	const domain = email.split('@')[1];
	if (!domain) return false;

	if (blockedTemporaryEmailDomains.includes(domain)) {
		return false;
	}

	return true;
};

/**
 * @name validateIsRequired
 * @description Validates a required field
 * @param {String} value
 * @param {String} fieldName
 * @returns {String|Boolean}
 * @example validateIsRequired("Hello", "name") => true
 * @example validateIsRequired("", "name") => false
 */
export const validateIsRequired = value => {
	if (value === undefined || value === null) return false;

	return true;
};

/**
 * @name validateType
 * @description Valida el tipo de dato
 * @param {*} value - El valor a validar
 * @param {String} expectedType - El tipo esperado ("string", "number", "object", "array", "boolean", "date")
 * @returns {Boolean} - True si el valor es del tipo esperado, false en caso contrario
 * @example validateType("Hello", "string") => true
 * @example validateType(123, "number") => true
 * @example validateType({}, "object") => true
 * @example validateType([], "array") => true
 * @example validateType(true, "boolean") => true
 * @example validateType("2023-01-01", "date") => true
 */
export const validateIsValidType = (value, expectedType) => {
	switch (expectedType) {
		case 'string':
			return typeof value === 'string';
		case 'number':
			return typeof value === 'number';
		case 'object':
			return (
				typeof value === 'object' &&
				!Array.isArray(value) &&
				value !== null
			);
		case 'array':
			return Array.isArray(value);
		case 'boolean':
			return typeof value === 'boolean';
		case 'date':
			return !isNaN(new Date(value).getTime());
		default:
			return false;
	}
};

/**
 * @name validateIsEnumOption
 * @description Validates an enum option
 * @param {String} value
 * @param {Array<String>} options
 * @param {String} fieldName
 * @returns {String|Boolean}
 * @example validateIsEnumOption("Hello", ["Hello", "World"], "name") => true
 * @example validateIsEnumOption("Hello", ["World"], "name") => false
 */
export const validateIsEnumOption = (value, options) => {
	if (!options.includes(value)) return false;

	return true;
};

/**
 * @name validateIsValidPostalCode
 * @description Validates a postal code
 * @param {String} postalCode
 * @returns {String|Boolean}
 * @example validateIsValidPostalCode("12345") => true
 * @example validateIsValidPostalCode("1234") => false
 */
export const validateIsValidPostalCode = postalCode => {
	if (!/^\d{5}$/.test(postalCode)) return false;

	return true;
};

/**
 * @name validateIsValidCoordinates
 * @description Validates coordinates
 * @param {Object} coordinates
 * @returns {String|Boolean}
 * @example validateIsValidCoordinates({ lat: "123", lng: "123" }) => true
 * @example validateIsValidCoordinates( lat: "123", lng: "" ) => false
 * @example validateIsValidCoordinates({ lat: "123" }) => false
 */
export const validateIsValidCoordinates = coordinates => {
	if (
		typeof coordinates !== 'object' ||
		!coordinates.lat ||
		!coordinates.long
	)
		return false;

	return true;
};

/**
 * @name validateIsValidDate
 * @description Validates a date
 * @param {String} date
 * @param {String} fieldName
 * @returns {String|Boolean}
 * @example validateIsValidDate("2021-12-12", "date") => true
 * @example validateIsValidDate("2021-12-32", "date") => false
 */
export const validateIsValidDate = date => {
	if (isNaN(new Date(date).getTime())) return false;

	return true;
};

/**
 * @name validateIsValidDateRange
 * @description Validates a date range
 * @param {String} startDate
 * @param {String} endDate
 * @returns {String|Boolean}
 * @example validateIsValidDateRange("2021-12-12", "2021-12-13") => true
 * @example validateIsValidDateRange("2021-12-13", "2021-12-12") => false
 */
export const validateIsValidDateRange = (startDate, endDate) => {
	if (new Date(startDate) > new Date(endDate)) return false;

	return true;
};

/**
 * @name validateIsPositiveNumber
 * @description Validates a positive number
 * @param {Number} value
 * @param {String} fieldName
 * @returns {String|Boolean}
 * @example validateIsPositiveNumber(123, "number") => true
 * @example validateIsPositiveNumber(-123, "number") => false
 */
export const validateIsPositiveNumber = value => {
	if (value < 0) return false;

	return true;
};

/**
 * @name validateIsValidImage
 * @description Validates an image
 * @param {String} image
 * @returns {String|Boolean}
 * @example validateIsValidImage("image.jpg") => true
 * @example validateIsValidImage("image.txt") => false
 */
export const validateIsValidImage = image => {
	if (!/\.(jpg|jpeg|png)$/.test(image)) return false;

	return true;
};

/**
 * @name validateIsValidFilePhoto
 * @description Validates a user avatarImage
 * @param {Object} file
 * @returns {boolean}
 */
export const validateIsValidFilePhoto = file => {
	if (!file) return false;

	const mimeType = ['image/jpeg', 'image/png', 'image/jpg'];
	if (!mimeType.includes(file.mimetype)) return false;

	return true;
};

/**
 * @name validateIsValidFileSize
 * @description Validates a file size
 * @param {Object} file
 * @param {Number} maxSizeInBytes
 * @returns {String|Boolean}
 * @example validateIsValidFileSize(file, 1024) => true
 * @example validateIsValidFileSize(file, 512) => false
 */
const validateIsValidFileSize = (file, maxSizeInBytes) => {
	if (!file || !file.size) return false;
	return file.size <= maxSizeInBytes;
};

/**
 * @name validateIsValidEmvWalletAddress
 * @description Validates an EMV wallet address
 * @param {String} address
 * @returns {String|Boolean}
 * @example validateIsValidEmvWalletAddress("0x1234567890123456789012345678901234567890") => true
 * @example validateIsValidEmvWalletAddress("1234567890123456789012345678901234567890") => false
 */
export const validateIsValidEmvWalletAddress = address => {
	if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) return false;

	return true;
};

/**
 * @name validateIsValidLength
 * @description Validates a value length
 * @param {String} value
 * @param {Number} min
 * @param {Number} max
 * @returns {String|Boolean}
 * @example validateIsValidLength("Hello", 4, 10) => true
 * @example validateIsValidLength("Hello", 6, 10) => false
 */
export const validateIsValidLength = (value, min, max) => {
	if (value.length < min || value.length > max) return false;

	return true;
};

/**
 * @name validateIsValidExactLength
 * @description Validates a value exact length
 * @param {String} value
 * @param {Number} length
 * @returns {String|Boolean}
 * @example validateIsValidExactLength("Hello", 5) => true
 * @example validateIsValidExactLength("Hello", 6) => false
 */
export const validateIsValidExactLength = (value, length) => {
	if (value.length !== length) return false;

	return true;
};

export default {
	validateIsSeveralEmptyValues,
	validateIsOneEmptyValue,
	validateIsValidEmail,
	validateIsValidEmailDomain,
	validateIsRequired,
	validateIsValidType,
	validateIsEnumOption,
	validateIsValidPostalCode,
	validateIsValidCoordinates,
	validateIsValidDate,
	validateIsValidDateRange,
	validateIsPositiveNumber,
	validateIsValidImage,
	validateIsValidFilePhoto,
	validateIsValidFileSize,
	validateIsValidEmvWalletAddress,
	validateIsValidLength,
	validateIsValidExactLength,
};
