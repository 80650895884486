import {
	getUSDBalanceInWallet,
	getWalletBalances,
} from '../services/walletService';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { updateUser } from '../redux/states/user';
import useContracts from './useContracts';
import { getEthereumBalance } from '../services/ethereumServices';
import { getTokenAmount } from '../utils/utils';

const useInfoData = ({ walletAddress }) => {
	const user = useSelector(state => state.user);
	const [balances, setBalances] = useState(user.balances || {});
	const { usdcContract, usdtContract } = useContracts();
	const dispatch = useDispatch();

	const updateBalancesInState = (key, newBalance) => {
		setBalances(prevBalances => ({
			...prevBalances,
			[key]: parseFloat(newBalance).toFixed(3).toString(),
		}));

		dispatch(
			updateUser({
				balances: {
					...balances,
					[key]: parseFloat(newBalance).toFixed(3).toString(),
				},
			}),
		);
	};

	const updateTokenBalance = async (
		from,
		to,
		value,
		walletAddress,
		tokenKey,
		formattedBalanceKey,
	) => {
		const amount = getTokenAmount(value, 6); // Conversion de token a formato decimal
		const currentBalance = parseFloat(balances[formattedBalanceKey]);

		const newBalance =
			from === walletAddress
				? currentBalance - amount
				: currentBalance + amount;

		updateBalancesInState(formattedBalanceKey, newBalance);
	};

	const getBalances = async () => {
		const walletBalances = await getWalletBalances(walletAddress);
		const usdBalance = await getUSDBalanceInWallet(walletBalances);

		dispatch(updateUser({ balances: { usdBalance, ...walletBalances } }));
		setBalances({ usdBalance, ...walletBalances });
	};

	const updateETHBalance = async () => {
		const ethBalance = await getEthereumBalance(walletAddress);
		if (ethBalance !== balances.formattedEthBalance) {
			updateBalancesInState('formattedEthBalance', ethBalance);
		}
	};

	useEffect(() => {
		getBalances();
		const handleTransferEvent = (
			from,
			to,
			value,
			tokenKey,
			formattedBalanceKey,
		) => {
			if (from === walletAddress || to === walletAddress) {
				updateTokenBalance(
					from,
					to,
					value,
					walletAddress,
					tokenKey,
					formattedBalanceKey,
				);
			}
		};

		usdcContract.on('Transfer', (from, to, value) => {
			handleTransferEvent(
				from,
				to,
				value,
				'USDC',
				'formattedUSDCBalance',
			);
		});

		usdtContract.on('Transfer', (from, to, value) => {
			handleTransferEvent(
				from,
				to,
				value,
				'USDT',
				'formattedUSDTBalance',
			);
		});

		setInterval(updateETHBalance, 5000);
	}, [walletAddress]);

	return { balances };
};

export default useInfoData;
