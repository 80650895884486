import GeneralDataValidations from './generalDataValidations.js';

/* ----------- Constants ------------- */
const PURCHASED_OR_PLEDGE_OPTIONS = ['Purchased', 'Pledge'];
const PROJECT_STATUS_OPTIONS = [
	'Upcoming',
	'Open',
	'Funded-Open Market',
	'Cancelled-Refund',
	'Ended-Burned',
];
const CATEGORIES_OPTIONS = ['Rental', 'Flip', 'Build'];
const SUBCATEGORIES_OPTIONS = [
	'Off-plan to rent',
	'Buy & renovate to rent',
	'Buy to rent',
	'Buy to sell',
	'Off-plan to sell',
	'Buy & renovate to sell',
	'Build to rent',
	'Build to sell',
];
const PROPERTY_TYPE_OPTIONS = [
	'Villa',
	'Apartament',
	'Building',
	'Townhouse',
	'Cottage',
	'Hotel',
];
const DISTRIBUTION_FREQUENCY_OPTIONS = [
	'Monthly',
	'Quarterly',
	'Annually',
	'Date of sale',
];
/* ----------------------------------- */

// Validate project data
export const validateProjectData = (
	isUpdate,
	{
		purchasedOrPledge,
		projectStatus,
		category,
		subcategory,
		title,
		postedBy,
		address,
		country,
		city,
		postalCode,
		coordinates,
		description,
		propertyType,
		areaM2,
		financingStart,
		financingEnd,
		projectStart,
		projectEnd,
		featuredImage,
		isKYCRequired,
		pricePerToken,
		quantityOfTokens,
		paybackPeriod,
		minTicketPrice,
		investmentThreshold,
		distributionFrequency,
		withdrawWalletAddress,
		currentPropertyValue,
		taxesAndOperatingCosts,
		renovationAndFitoutsCosts,
		estimatedAnnualIncomeFromRental,
		rentalTimeline,
		estimatedResalePrice,
		appreciation,
	},
) => {
	const input = [];

	// Check purchasedOrPledge
	if (!isUpdate || (purchasedOrPledge && isUpdate)) {
		let isValid = true;
		if (!GeneralDataValidations.validateIsRequired(purchasedOrPledge)) {
			isValid = false;
		} else {
			if (
				!GeneralDataValidations.validateIsOneEmptyValue(
					purchasedOrPledge,
				)
			) {
				isValid = false;
			}
			if (
				!GeneralDataValidations.validateIsValidType(
					purchasedOrPledge,
					'string',
				)
			)
				isValid = false;
			if (
				!GeneralDataValidations.validateIsEnumOption(
					purchasedOrPledge,
					PURCHASED_OR_PLEDGE_OPTIONS,
				)
			)
				isValid = false;
		}

		if (!isValid) {
			input.push('purchasedOrPledge');
		}
	}

	// Check projectStatus
	if (!isUpdate || (projectStatus && isUpdate)) {
		let isValid = true;
		if (!GeneralDataValidations.validateIsRequired(projectStatus)) {
			isValid = false;
		} else {
			if (!GeneralDataValidations.validateIsOneEmptyValue(projectStatus))
				isValid = false;
			if (
				!GeneralDataValidations.validateIsValidType(
					projectStatus,
					'string',
				)
			)
				isValid = false;
			if (
				!GeneralDataValidations.validateIsEnumOption(
					projectStatus,
					PROJECT_STATUS_OPTIONS,
				)
			)
				isValid = false;
		}

		if (!isValid) {
			input.push('projectStatus');
		}
	}

	// Check category
	if (!isUpdate || (category && isUpdate)) {
		let isValid = true;

		if (!GeneralDataValidations.validateIsRequired(category)) {
			isValid = false;
		} else {
			if (!GeneralDataValidations.validateIsOneEmptyValue(category))
				isValid = false;
			if (!GeneralDataValidations.validateIsValidType(category, 'string'))
				isValid = false;
			if (
				!GeneralDataValidations.validateIsEnumOption(
					category,
					CATEGORIES_OPTIONS,
				)
			)
				isValid = false;
		}

		if (!isValid) {
			input.push('category');
		}
	}

	// Check subcategory
	if (!isUpdate || (subcategory && isUpdate)) {
		let isValid = true;

		if (!GeneralDataValidations.validateIsRequired(subcategory)) {
			isValid = false;
		} else {
			if (!GeneralDataValidations.validateIsOneEmptyValue(subcategory))
				isValid = false;

			if (
				!GeneralDataValidations.validateIsValidType(
					subcategory,
					'string',
				)
			)
				isValid = false;

			if (
				!GeneralDataValidations.validateIsEnumOption(
					subcategory,
					SUBCATEGORIES_OPTIONS,
				)
			)
				isValid = false;
		}

		if (!isValid) {
			input.push('subcategory');
		}
	}

	// Check title
	if (!isUpdate || (title && isUpdate)) {
		let isValid = true;

		if (!GeneralDataValidations.validateIsRequired(title)) {
			isValid = false;
		} else {
			if (!GeneralDataValidations.validateIsOneEmptyValue(title)) {
				isValid = false;
			}

			if (!GeneralDataValidations.validateIsValidType(title, 'string')) {
				isValid = false;
			}
		}

		if (!isValid) {
			input.push('title');
		}
	}

	// Check postedBy
	if (!isUpdate || (postedBy && isUpdate)) {
		let isValid = true;

		if (!GeneralDataValidations.validateIsRequired(postedBy)) {
			isValid = false;
		} else {
			if (!GeneralDataValidations.validateIsOneEmptyValue(postedBy))
				isValid = false;
			if (!GeneralDataValidations.validateIsValidType(postedBy, 'string'))
				isValid = false;
			if (postedBy !== 'Elevex') isValid = false;
		}

		if (!isValid) {
			input.push('postedBy');
		}
	}

	// Check address
	if (!isUpdate || (address && isUpdate)) {
		let isValid = true;

		if (!GeneralDataValidations.validateIsRequired(address)) {
			isValid = false;
		} else {
			if (!GeneralDataValidations.validateIsOneEmptyValue(address))
				isValid = false;
			if (!GeneralDataValidations.validateIsValidType(address, 'string'))
				isValid = false;
		}

		if (!isValid) {
			input.push('address');
		}
	}

	// Check country
	if (!isUpdate || (country && isUpdate)) {
		let isValid = true;

		if (!GeneralDataValidations.validateIsRequired(country)) {
			isValid = false;
		} else {
			if (!GeneralDataValidations.validateIsOneEmptyValue(country))
				isValid = false;
			if (!GeneralDataValidations.validateIsValidType(country, 'string'))
				isValid = false;
		}

		if (!isValid) {
			input.push('address');
		}
	}

	// Check city
	if (!isUpdate || (city && isUpdate)) {
		let isValid = true;

		if (!GeneralDataValidations.validateIsRequired(city)) {
			isValid = false;
		} else {
			if (!GeneralDataValidations.validateIsOneEmptyValue(city))
				isValid = false;
			if (!GeneralDataValidations.validateIsValidType(city, 'string'))
				isValid = false;
		}

		if (!isValid) {
			input.push('address');
		}
	}

	// Check postalCode
	// There are times when it will not come because google does not send it, to avoid errors the zip code can be null.
	if (postalCode !== '' && postalCode !== null && postalCode !== undefined) {
		let isValid = true;

		if (!GeneralDataValidations.validateIsOneEmptyValue(postalCode)) {
			isValid = false;
		} else {
			if (
				!GeneralDataValidations.validateIsValidType(
					postalCode,
					'string',
				)
			)
				isValid = false;
			if (!GeneralDataValidations.validateIsValidPostalCode(postalCode))
				isValid = false;
		}

		if (!isValid) {
			input.push('address');
		}
	}

	// Check coordinates
	if (!isUpdate || (coordinates && isUpdate)) {
		let isValid = true;

		if (!GeneralDataValidations.validateIsRequired(coordinates)) {
			isValid = false;
		} else {
			if (
				!GeneralDataValidations.validateIsValidType(
					coordinates,
					'object',
				)
			)
				isValid = false;
			if (!GeneralDataValidations.validateIsValidCoordinates(coordinates))
				isValid = false;
			if (
				!GeneralDataValidations.validateIsOneEmptyValue(coordinates.lat)
			)
				isValid = false;
			if (
				!GeneralDataValidations.validateIsOneEmptyValue(
					coordinates.long,
				)
			)
				isValid = false;
		}

		if (!isValid) {
			input.push('address');
		}
	}

	// Check description
	if (!isUpdate || (description && isUpdate)) {
		let isValid = true;

		if (!GeneralDataValidations.validateIsRequired(description)) {
			isValid = false;
		} else {
			if (!GeneralDataValidations.validateIsOneEmptyValue(description))
				isValid = false;
			if (
				!GeneralDataValidations.validateIsValidType(
					description,
					'string',
				)
			)
				isValid = false;
		}

		if (!isValid) {
			input.push('description');
		}
	}

	// Check propertyType
	if (!isUpdate || (propertyType && isUpdate)) {
		let isValid = true;

		if (!GeneralDataValidations.validateIsRequired(propertyType)) {
			isValid = false;
		} else {
			if (!GeneralDataValidations.validateIsOneEmptyValue(propertyType))
				isValid = false;
			if (
				!GeneralDataValidations.validateIsValidType(
					propertyType,
					'string',
				)
			)
				isValid = false;
			if (
				!GeneralDataValidations.validateIsEnumOption(
					propertyType,
					PROPERTY_TYPE_OPTIONS,
				)
			)
				isValid = false;
		}

		if (!isValid) {
			input.push('propertyType');
		}
	}

	// Check areaM2
	if (!isUpdate || (areaM2 && isUpdate)) {
		let isValid = true;

		if (!GeneralDataValidations.validateIsRequired(areaM2)) {
			isValid = false;
		} else {
			if (!GeneralDataValidations.validateIsValidType(areaM2, 'number')) {
				isValid = false;
			}
			if (!GeneralDataValidations.validateIsPositiveNumber(areaM2))
				isValid = false;
		}

		if (!isValid) {
			input.push('areaM2');
		}
	}

	// Check financingStart
	if (
		!isUpdate ||
		(financingStart && isUpdate) ||
		(financingEnd && isUpdate)
	) {
		let isValid = true;

		if (!GeneralDataValidations.validateIsRequired(financingStart)) {
			isValid = false;
		} else {
			if (
				!GeneralDataValidations.validateIsValidType(
					financingStart,
					'date',
				)
			)
				isValid = false;
			if (!GeneralDataValidations.validateIsValidDate(financingStart))
				isValid = false;
		}

		if (!isValid) {
			input.push('financingStart');
		}
	}

	// Check financingEnd
	if (
		!isUpdate ||
		(financingEnd && isUpdate) ||
		(financingStart && isUpdate)
	) {
		let isValid = true;

		if (!GeneralDataValidations.validateIsRequired(financingEnd)) {
			isValid = false;
		} else {
			if (
				!GeneralDataValidations.validateIsValidType(
					financingEnd,
					'date',
				)
			)
				isValid = false;
			if (!GeneralDataValidations.validateIsValidDate(financingEnd))
				isValid = false;
		}

		if (!isValid) {
			input.push('financingEnd');
		}
	}

	// Check range date financingStart and financingEnd
	if (!isUpdate || (financingStart && financingEnd && isUpdate)) {
		let isValid = true;

		if (
			!GeneralDataValidations.validateIsValidDateRange(
				financingStart,
				financingEnd,
			)
		)
			isValid = false;

		if (!isValid) {
			input.push('financingStart');
			input.push('financingEnd');
		}
	}

	// Check projectStart
	if (!isUpdate || (projectStart && isUpdate) || (projectStart && isUpdate)) {
		let isValid = true;

		if (!GeneralDataValidations.validateIsRequired(projectStart)) {
			isValid = false;
		} else {
			if (
				!GeneralDataValidations.validateIsOneEmptyValue(
					projectStart.from,
				) &&
				!GeneralDataValidations.validateIsOneEmptyValue(projectStart.to)
			)
				isValid = false;
			if (!projectStart.day) {
				if (
					!GeneralDataValidations.validateIsValidType(
						projectStart.to,
						'date',
					) &&
					!GeneralDataValidations.validateIsValidType(
						projectStart.from,
						'date',
					)
				)
					isValid = false;
				if (
					!GeneralDataValidations.validateIsValidType(
						projectStart.from,
						'date',
					) &&
					!GeneralDataValidations.validateIsValidType(
						projectStart.to,
						'date',
					)
				)
					isValid = false;
				if (
					!GeneralDataValidations.validateIsValidDate(
						projectStart.to,
					) &&
					!GeneralDataValidations.validateIsValidDate(
						projectStart.from,
					)
				)
					isValid = false;
			}

			if (!GeneralDataValidations.validateIsValidDate(projectStart.day))
				isValid = false;
		}

		if (!isValid) {
			input.push('projectStart');
		}
	}

	// Check projectEnd
	if (!isUpdate || (projectEnd && isUpdate) || (projectStart && isUpdate)) {
		let isValid = true;

		if (!GeneralDataValidations.validateIsRequired(projectEnd)) {
			isValid = false;
		} else {
			if (
				!GeneralDataValidations.validateIsOneEmptyValue(
					projectEnd.from,
				) &&
				!GeneralDataValidations.validateIsOneEmptyValue(projectEnd.to)
			)
				isValid = false;
			if (!projectEnd.day) {
				if (
					!GeneralDataValidations.validateIsValidType(
						projectEnd.to,
						'date',
					) &&
					!GeneralDataValidations.validateIsValidType(
						projectEnd.from,
						'date',
					)
				)
					isValid = false;
				if (
					!GeneralDataValidations.validateIsValidType(
						projectEnd.from,
						'date',
					) &&
					!GeneralDataValidations.validateIsValidType(
						projectEnd.to,
						'date',
					)
				)
					isValid = false;
				if (
					!GeneralDataValidations.validateIsValidDate(
						projectEnd.to,
					) &&
					!GeneralDataValidations.validateIsValidDate(projectEnd.from)
				)
					isValid = false;
			}

			if (!GeneralDataValidations.validateIsValidDate(projectEnd.day))
				isValid = false;
		}

		if (!isValid) {
			input.push('projectEnd');
		}
	}

	// Check featuredImage
	if (!isUpdate || (featuredImage && isUpdate)) {
		let isValid = true;

		if (!GeneralDataValidations.validateIsRequired(featuredImage)) {
			isValid = false;
		} else {
			if (!GeneralDataValidations.validateIsOneEmptyValue(featuredImage))
				isValid = false;
			if (
				!GeneralDataValidations.validateIsValidType(
					featuredImage,
					'string',
				)
			)
				isValid = false;
			if (!GeneralDataValidations.validateIsValidImage(featuredImage))
				isValid = false;
		}

		if (!isValid) {
			input.push('featuredImage');
		}
	}

	// Check isKYCRequired
	if (!isUpdate || (isKYCRequired && isUpdate)) {
		let isValid = true;

		if (!GeneralDataValidations.validateIsRequired(isKYCRequired)) {
			isValid = false;
		} else {
			if (!GeneralDataValidations.validateIsOneEmptyValue(isKYCRequired))
				isValid = false;
			if (
				!GeneralDataValidations.validateIsValidType(
					isKYCRequired,
					'boolean',
				)
			)
				isValid = false;
		}

		if (!isValid) {
			input.push('isKYCRequired');
		}
	}

	// Check pricePerToken
	if (!isUpdate || (pricePerToken && isUpdate)) {
		let isValid = true;

		if (!GeneralDataValidations.validateIsRequired(pricePerToken)) {
			isValid = false;
		} else {
			if (
				!GeneralDataValidations.validateIsValidType(
					pricePerToken,
					'number',
				)
			)
				isValid = false;
			if (!GeneralDataValidations.validateIsPositiveNumber(pricePerToken))
				isValid = false;
		}

		if (!isValid) {
			input.push('pricePerToken');
		}
	}

	// Check quantityOfTokens
	if (!isUpdate || (quantityOfTokens && isUpdate)) {
		let isValid = true;

		if (!GeneralDataValidations.validateIsRequired(quantityOfTokens)) {
			isValid = false;
		} else {
			if (
				!GeneralDataValidations.validateIsValidType(
					quantityOfTokens,
					'number',
				)
			)
				isValid = false;
			if (
				!GeneralDataValidations.validateIsPositiveNumber(
					quantityOfTokens,
				)
			)
				isValid = false;
		}

		if (!isValid) {
			input.push('quantityOfTokens');
		}
	}

	// Check paybackPeriod
	if (!isUpdate || (paybackPeriod && isUpdate)) {
		let isValid = true;

		if (!GeneralDataValidations.validateIsRequired(paybackPeriod)) {
			isValid = false;
		} else {
			if (
				!GeneralDataValidations.validateIsValidType(
					paybackPeriod,
					'number',
				)
			)
				isValid = false;
			if (!GeneralDataValidations.validateIsPositiveNumber(paybackPeriod))
				isValid = false;
		}

		if (!isValid) {
			input.push('paybackPeriod');
		}
	}

	// Check minTicketPrice
	if (!isUpdate || (minTicketPrice && isUpdate)) {
		let isValid = true;

		if (!GeneralDataValidations.validateIsRequired(minTicketPrice)) {
			isValid = false;
		} else {
			if (
				!GeneralDataValidations.validateIsValidType(
					minTicketPrice,
					'number',
				)
			)
				isValid = false;
			if (
				!GeneralDataValidations.validateIsPositiveNumber(minTicketPrice)
			)
				isValid = false;
		}

		if (!isValid) {
			input.push('minTicketPrice');
		}
	}

	// Check investmentThreshold
	if (!isUpdate || (investmentThreshold && isUpdate)) {
		let isValid = true;

		if (!GeneralDataValidations.validateIsRequired(investmentThreshold)) {
			isValid = false;
		} else {
			if (
				!GeneralDataValidations.validateIsValidType(
					investmentThreshold,
					'number',
				)
			)
				isValid = false;
			if (
				!GeneralDataValidations.validateIsPositiveNumber(
					investmentThreshold,
				)
			)
				isValid = false;
		}

		if (!isValid) {
			input.push('investmentThreshold');
		}
	}

	// Check distributionFrequency
	if (!isUpdate || (distributionFrequency && isUpdate)) {
		let isValid = true;

		if (!GeneralDataValidations.validateIsRequired(distributionFrequency)) {
			isValid = false;
		} else {
			if (
				!GeneralDataValidations.validateIsOneEmptyValue(
					distributionFrequency,
				)
			)
				isValid = false;
			if (
				!GeneralDataValidations.validateIsValidType(
					distributionFrequency,
					'string',
				)
			)
				isValid = false;
			if (
				!GeneralDataValidations.validateIsEnumOption(
					distributionFrequency,
					DISTRIBUTION_FREQUENCY_OPTIONS,
				)
			)
				isValid = false;
		}

		if (!isValid) {
			input.push('distributionFrequency');
		}
	}

	// Check withdrawWalletAddress
	if (!isUpdate || (withdrawWalletAddress && isUpdate)) {
		let isValid = true;

		if (!GeneralDataValidations.validateIsRequired(withdrawWalletAddress)) {
			isValid = false;
		} else {
			if (
				!GeneralDataValidations.validateIsOneEmptyValue(
					withdrawWalletAddress,
				)
			)
				isValid = false;
			if (
				!GeneralDataValidations.validateIsValidType(
					withdrawWalletAddress,
					'string',
				)
			)
				isValid = false;
			if (
				!GeneralDataValidations.validateIsValidEmvWalletAddress(
					withdrawWalletAddress,
				)
			)
				isValid = false;
		}

		if (!isValid) {
			input.push('withdrawWalletAddress');
		}
	}

	// Check currentPropertyValue

	if (!isUpdate || (currentPropertyValue && isUpdate)) {
		let isValid = true;

		if (!GeneralDataValidations.validateIsRequired(currentPropertyValue)) {
			isValid = false;
		} else {
			if (
				!GeneralDataValidations.validateIsValidType(
					currentPropertyValue,
					'number',
				)
			)
				isValid = false;
			if (
				!GeneralDataValidations.validateIsPositiveNumber(
					currentPropertyValue,
				)
			)
				isValid = false;
		}

		if (!isValid) {
			input.push('currentPropertyValue');
		}
	}

	// Check taxesAndOperatingCosts
	if (!isUpdate || (taxesAndOperatingCosts && isUpdate)) {
		let isValid = true;

		if (
			!GeneralDataValidations.validateIsRequired(taxesAndOperatingCosts)
		) {
			isValid = false;
		} else {
			if (
				!GeneralDataValidations.validateIsValidType(
					taxesAndOperatingCosts,
					'number',
				)
			)
				isValid = false;
			if (
				!GeneralDataValidations.validateIsPositiveNumber(
					taxesAndOperatingCosts,
				)
			)
				isValid = false;
		}

		if (!isValid) {
			input.push('taxesAndOperatingCosts');
		}
	}

	// Check renovationAndFitoutsCosts
	if (!isUpdate || (renovationAndFitoutsCosts && isUpdate)) {
		let isValid = true;

		if (
			!GeneralDataValidations.validateIsRequired(
				renovationAndFitoutsCosts,
			)
		) {
			isValid = false;
		} else {
			if (
				!GeneralDataValidations.validateIsValidType(
					renovationAndFitoutsCosts,
					'number',
				)
			)
				isValid = false;
			if (
				!GeneralDataValidations.validateIsPositiveNumber(
					renovationAndFitoutsCosts,
				)
			)
				isValid = false;
		}

		if (!isValid) {
			input.push('renovationAndFitoutsCosts');
		}
	}

	// Check estimatedAnnualIncomeFromRental
	if (!isUpdate || (estimatedAnnualIncomeFromRental && isUpdate)) {
		let isValid = true;

		if (
			!GeneralDataValidations.validateIsRequired(
				estimatedAnnualIncomeFromRental,
			)
		) {
			isValid = false;
		} else {
			if (
				!GeneralDataValidations.validateIsValidType(
					estimatedAnnualIncomeFromRental,
					'number',
				)
			)
				isValid = false;
			if (
				!GeneralDataValidations.validateIsPositiveNumber(
					estimatedAnnualIncomeFromRental,
				)
			)
				isValid = false;
		}

		if (!isValid) {
			input.push('estimatedAnnualIncomeFromRental');
		}
	}

	// Check rentalTimeline
	if (!isUpdate || (rentalTimeline && isUpdate)) {
		let isValid = true;

		if (!GeneralDataValidations.validateIsRequired(rentalTimeline)) {
			isValid = false;
		} else {
			if (
				!GeneralDataValidations.validateIsValidType(
					rentalTimeline,
					'number',
				)
			)
				isValid = false;
			if (
				!GeneralDataValidations.validateIsPositiveNumber(rentalTimeline)
			)
				isValid = false;
		}

		if (!isValid) {
			input.push('rentalTimeline');
		}
	}

	// Check estimatedResalePrice
	if (!isUpdate || (estimatedResalePrice && isUpdate)) {
		let isValid = true;

		if (!GeneralDataValidations.validateIsRequired(estimatedResalePrice)) {
			isValid = false;
		} else {
			if (
				!GeneralDataValidations.validateIsValidType(
					estimatedResalePrice,
					'number',
				)
			)
				isValid = false;
			if (
				!GeneralDataValidations.validateIsPositiveNumber(
					estimatedResalePrice,
				)
			)
				isValid = false;
		}

		if (!isValid) {
			input.push('estimatedResalePrice');
		}
	}

	// Check appreciation
	if (!isUpdate || (appreciation && isUpdate)) {
		let isValid = true;

		if (!GeneralDataValidations.validateIsRequired(appreciation)) {
			isValid = false;
		} else {
			if (
				!GeneralDataValidations.validateIsValidType(
					appreciation,
					'number',
				)
			)
				isValid = false;
			if (!GeneralDataValidations.validateIsPositiveNumber(appreciation))
				isValid = false;
		}

		if (!isValid) {
			input.push('appreciation');
		}
	}

	return input;
};
