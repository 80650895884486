/* eslint-disable react-hooks/exhaustive-deps */
import {
	purchasedOrPledgeOptions,
	projectStatusOptions,
	projectCategoryOptions,
	buildSubcategories,
	rentalSubcategories,
	flipSubcategories,
	propertyTypeOptions,
	postedByOptions,
} from '../../../../data/optionsData';
import {
	Box,
	Button,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	Input,
	Stack,
	Text,
	Textarea,
	Tooltip,
	useColorMode,
} from '@chakra-ui/react';
import MenuSelectComponent from '../../../../components/MenuSelectComponent';
import {
	IconPlus,
	IconBalcony,
	IconBathroom,
	IconBBQ,
	IconGym,
	IconLibrary,
	IconParking,
	IconPool,
	IconRooms,
	IconSea,
	IconHelp,
} from '../../../../assets/Icons';
import AddEquipmentModal from './AddEquipmentModal';
import { useEffect, useState } from 'react';
import EquipmentBoxComponent from './EquipmentBoxComponent';
import AddressAutocomplete from '../../../../components/GooglePlacesComponent/AddressAutocomplete';
import DateDayPickerComponent from '../../../../components/DatePickerComponent/DateDayPickerComponent';
import DateMonthPickerComponent from '../../../../components/DatePickerComponent/DateMonthPickerComponent';
import { addDaysToDate } from '../../../../utils/CalculateTime';

const initialEquipments = [
	{ name: 'room', icon: IconRooms, value: 0 },
	{ name: 'bathroom', icon: IconBathroom, value: 0 },
	{ name: 'balcony', icon: IconBalcony, value: false },
	{ name: 'parking', icon: IconParking, value: 0 },
	{ name: 'barbacue', icon: IconBBQ, value: false },
	{ name: 'gym', icon: IconGym, value: false },
	{ name: 'library', icon: IconLibrary, value: false },
	{ name: 'pool', icon: IconPool, value: false },
	{ name: 'seaView', icon: IconSea, value: false },
];

const getSubcategories = category => {
	switch (category) {
		case 'Build':
			return buildSubcategories;
		case 'Rental':
			return rentalSubcategories;
		case 'Flip':
			return flipSubcategories;
	}
};
const initialInformationTabInfo = {
	purchasedOrPledge: purchasedOrPledgeOptions[0],
	projectStatus: projectStatusOptions[0],
	category: projectCategoryOptions[0],
	subcategory: rentalSubcategories[0],
	propertyType: propertyTypeOptions[0],
	postedBy: postedByOptions[0],
};

const InformationComponent = ({
	selectedSuggestion,
	setSelectedSuggestion,
	propertyInfo,
	setPropertyInfo,
	incorrectData,
}) => {
	const { colorMode } = useColorMode();
	const [informationTabInfo, setInformationTabInfo] = useState(
		initialInformationTabInfo,
	);

	const [showEquipmentModal, setShowEquipmentModal] = useState(false);
	const [equipments, setEquipments] = useState(initialEquipments);

	const openEquipmentModal = () => setShowEquipmentModal(true);
	const closeEquipmentModal = () => setShowEquipmentModal(false);

	useEffect(() => {
		setPropertyInfo(prevState => ({
			...prevState,
			data: {
				...prevState.data,
				...informationTabInfo,
			},
		}));
	}, [informationTabInfo]);

	useEffect(() => {
		const transformedEquipments = Object.fromEntries(
			equipments.map(({ name, value }) => [name, value]),
		);

		setPropertyInfo(prevState => ({
			...prevState,
			equipments: {
				...prevState.equipments,
				...transformedEquipments,
			},
		}));
	}, [equipments]);

	const handleInfoTab = event => {
		const { name, value, type } = event.target;

		const parsedValue = type === 'number' ? parseFloat(value) : value;

		setPropertyInfo(prevState => ({
			...prevState,
			data: {
				...prevState.data,
				[name]: parsedValue,
			},
		}));

		delete incorrectData[incorrectData.indexOf(name)];
	};

	return (
		<>
			<Grid templateColumns='repeat(12, 1fr)' gap='25px'>
				<FormControl
					gridColumn='span 3'
					display='flex'
					flexDir='column'
					justifyContent='space-between'
				>
					<FormLabel variant='propertyLabel'>
						Project status
					</FormLabel>
					<MenuSelectComponent
						options={projectStatusOptions}
						tabInfo={informationTabInfo}
						setTabInfo={setInformationTabInfo}
						fieldName='projectStatus'
					/>
				</FormControl>
				<FormControl
					gridColumn='span 3'
					display='flex'
					flexDir='column'
					justifyContent='space-between'
				>
					<FormLabel
						display='flex'
						alignItems='center'
						gap='5px'
						color='transparencyWhite.500'
					>
						<Text
							variant='colored'
							fontSize='16px'
							fontWeight='700'
							lineHeight='20px'
						>
							Purchased/Pledge
						</Text>
						<Tooltip
							placement='right'
							label={
								'Purchased: The project creator holds current ownership of the property being offered in this listing.\nPledge: The project creator is seeking to raise funds to acquire the property being listed.'
							}
							sx={{ whiteSpace: 'pre-line' }}
						>
							<span>
								<IconHelp cursor='pointer' />
							</span>
						</Tooltip>
					</FormLabel>
					<MenuSelectComponent
						options={purchasedOrPledgeOptions}
						tabInfo={informationTabInfo}
						setTabInfo={setInformationTabInfo}
						fieldName='purchasedOrPledge'
					/>
				</FormControl>
				<Stack gridColumn='span 6' w='100%'>
					<Text
						variant='colored'
						fontSize='16px'
						fontWeight='700'
						lineHeight='20px'
					>
						Project type
					</Text>
					<Flex w='100%' justifyContent='space-between' gap='25px'>
						<FormControl>
							<FormLabel>
								<Text
									variant='gray'
									fontSize='13px'
									fontWeight='400'
									lineHeight='10px'
								>
									Categories
								</Text>
							</FormLabel>
							<MenuSelectComponent
								options={projectCategoryOptions}
								tabInfo={informationTabInfo}
								setTabInfo={setInformationTabInfo}
								fieldName='category'
							/>
						</FormControl>
						<FormControl>
							<FormLabel>
								<Text
									variant='gray'
									fontSize='13px'
									fontWeight='400'
									lineHeight='10px'
								>
									Subcategories
								</Text>
							</FormLabel>
							<MenuSelectComponent
								options={getSubcategories(
									informationTabInfo.category,
								)}
								tabInfo={informationTabInfo}
								setTabInfo={setInformationTabInfo}
								fieldName='subcategory'
							/>
						</FormControl>
					</Flex>
				</Stack>

				<FormControl
					gridColumn='span 6'
					border={
						incorrectData?.type === 'title' ? '1px solid red' : null
					}
				>
					<FormLabel variant='propertyLabel'>Project title</FormLabel>
					<Input
						name='title'
						placeholder='Title'
						value={propertyInfo.data.title}
						onChange={handleInfoTab}
						outline={
							incorrectData?.includes('title')
								? '1px solid red'
								: 'none'
						}
					/>
				</FormControl>
				<FormControl
					gridColumn='span 6'
					display='flex'
					flexDir='column'
					justifyContent='space-between'
				>
					<FormLabel variant='propertyLabel'>Posted by</FormLabel>
					<MenuSelectComponent
						options={postedByOptions}
						tabInfo={informationTabInfo}
						setTabInfo={setInformationTabInfo}
						fieldName='postedBy'
					/>
				</FormControl>

				<FormControl gridColumn='span 12'>
					<FormLabel variant='propertyLabel'>Address</FormLabel>
					<AddressAutocomplete
						selectedSuggestion={selectedSuggestion}
						setSelectedSuggestion={setSelectedSuggestion}
						incorrectData={incorrectData}
					/>
				</FormControl>
				<FormControl gridColumn='span 12'>
					<FormLabel variant='propertyLabel'>
						Project Description
					</FormLabel>
					<Textarea
						rows='4'
						name='description'
						placeholder='Describe the project'
						value={propertyInfo.data.description}
						onChange={handleInfoTab}
						outline={
							incorrectData?.includes('description')
								? '1px solid red'
								: 'none'
						}
					/>
				</FormControl>
				<Box gridColumn='span 12'>
					<FormLabel variant='propertyLabel'>Equipment</FormLabel>
					<Flex
						align='center'
						justify='center'
						border='0.5px dashed'
						borderColor={
							colorMode === 'dark' ? 'brand.200' : 'brand.500'
						}
						borderRadius='12px'
						p='15px'
						gap='15px'
						wrap='wrap'
					>
						{equipments.map(
							(equipment, i) =>
								(equipment.value === true ||
									equipment.value > 0) && (
									<EquipmentBoxComponent
										key={i}
										equipment={equipment}
										setEquipments={setEquipments}
									/>
								),
						)}
						<Button
							variant='main'
							display='flex'
							gap='5px'
							onClick={openEquipmentModal}
						>
							<IconPlus boxSize='13px' />
							Add equipment
						</Button>
					</Flex>
				</Box>
				<FormControl gridColumn='span 6'>
					<FormLabel variant='propertyLabel'>Property type</FormLabel>
					<MenuSelectComponent
						options={propertyTypeOptions}
						tabInfo={informationTabInfo}
						setTabInfo={setInformationTabInfo}
						fieldName='propertyType'
					/>
				</FormControl>
				<FormControl gridColumn='span 6'>
					<FormLabel variant='propertyLabel'>Area m2</FormLabel>
					<Input
						name='areaM2'
						placeholder='Area m2'
						value={propertyInfo.data.areaM2}
						onChange={handleInfoTab}
						type='number'
						outline={
							incorrectData?.includes('areaM2')
								? '1px solid red'
								: 'none'
						}
					/>
				</FormControl>

				<Box gridColumn='span 12'>
					<FormLabel
						display='flex'
						alignItems='center'
						gap='5px'
						color='transparencyWhite.500'
					>
						<Text
							variant='colored'
							fontSize='16px'
							fontWeight='700'
							lineHeight='20px'
						>
							Financing timeline
						</Text>
						<Tooltip
							placement='right'
							label='The financing timeline is the period when users can buy tokens in a project sale before they are available for trading on the open market.'
						>
							<span>
								<IconHelp cursor='pointer' />
							</span>
						</Tooltip>
					</FormLabel>

					<Flex gap='25px'>
						<Box w='100%'>
							<DateDayPickerComponent
								name='financingStart'
								setInfo={setPropertyInfo}
								incorrectData={incorrectData}
							/>
						</Box>
						<Box w='100%'>
							<DateDayPickerComponent
								name='financingEnd'
								setInfo={setPropertyInfo}
								incorrectData={incorrectData}
								minDate={addDaysToDate(1)}
							/>
						</Box>
					</Flex>
				</Box>
				<Box gridColumn='span 12'>
					<FormLabel variant='propertyLabel'>
						Project timeline
					</FormLabel>
					<Flex gap='25px'>
						<Box w='100%'>
							{informationTabInfo.purchasedOrPledge ===
							'Purchased' ? (
								<DateMonthPickerComponent
									name='projectStart'
									setInfo={setPropertyInfo}
									incorrectData={incorrectData}
								/>
							) : (
								<DateDayPickerComponent
									name='projectStart'
									setInfo={setPropertyInfo}
									jsonData
									incorrectData={incorrectData}
								/>
							)}
						</Box>
						<Box w='100%'>
							<DateMonthPickerComponent
								name='projectEnd'
								setInfo={setPropertyInfo}
								incorrectData={incorrectData}
							/>
						</Box>
					</Flex>
				</Box>
			</Grid>
			{showEquipmentModal && (
				<AddEquipmentModal
					onClose={closeEquipmentModal}
					isOpen={openEquipmentModal}
					equipments={equipments}
					setEquipments={setEquipments}
				/>
			)}
		</>
	);
};

export default InformationComponent;
