import { useState } from 'react';
import { Text, Box, Flex, Button } from '@chakra-ui/react';
import CardBoxComponent from '../../../../components/CardBoxComponent';
import Countdown from './CountdownComponent';
import ProjectIncomesInfo from './ProjectIncomesInfo';
import CalculatorROI from './CalculatorROI';
import AddCredit from './AddCredit';
import SuccessfulComponent from '../../../../components/SuccessfulComponent';
import {
	IconClose,
	IconCheck,
	IconInvest,
	IconWallet,
} from '../../../../assets/Icons';
import { multiplicationCalc } from '../../../../utils/utils';
import { userExample } from '../../../../data/mockdata';
import LoginComponent from '../../../../components/LoginComponent';
import ModalComponent from '../../../../components/ModalComponent';
import { useTranslation } from 'react-i18next';

const OfferDetails = ({ projectSelected }) => {
	const { t } = useTranslation();
	const [valueInTokens, setValueInTokens] = useState(true);
	const [valueInput, setValueInput] = useState();
	const [successfulBuy, setSuccessfulBuy] = useState(false);
	const [errMsg, setErrMsg] = useState('');
	const [buyMode, setBuyMode] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [modalLoginOpen, setModalLoginOpen] = useState(false);
	const isLoged = true;

	const openModal = () => setIsOpen(true);
	const closeModal = () => setIsOpen(false);
	const closeLoginModal = () => setModalLoginOpen(false);

	const onSubmit = () => {
		const canBuyInTokens =
			valueInTokens &&
			multiplicationCalc(projectSelected?.tokensPrize, valueInput) <=
				userExample.accBalance;
		const canBuyInCoins =
			!valueInTokens && valueInput <= userExample.accBalance;

		if (valueInput) {
			if (canBuyInTokens || canBuyInCoins) {
				setBuyMode(true);
			} else setErrMsg("Error, you don't have enought credit.");
		} else setErrMsg('Error, please insert a value');
	};

	return (
		<>
			{!successfulBuy && (
				<CardBoxComponent
					title={t('projectDetails.offerDetails.title')}
					pyCardBody='10px'
					minHeight='930px'
				>
					<Flex flexDirection='column' align='center' gap='10px'>
						<Box>
							<Text
								variant='smallColored'
								textAlign='center'
								pb='10px'
							>
								{t(
									'projectDetails.offerDetails.finaliseFunding',
								)}
							</Text>
							<Countdown
								endDate={projectSelected?.endDate}
							></Countdown>
						</Box>
						<ProjectIncomesInfo projectSelected={projectSelected} />
						<CalculatorROI
							projectSelected={projectSelected}
							userExample={userExample}
							valueInput={valueInput}
							setValueInput={setValueInput}
							valueInTokens={valueInTokens}
							setValueInTokens={setValueInTokens}
							setErrMsg={setErrMsg}
							setBuyMode={setBuyMode}
						/>

						{!buyMode && !isLoged && (
							<>
								<Button
									variant='main'
									fontSize='13'
									fontWeight='500'
									mb='5px'
									onClick={() => setModalLoginOpen(true)}
								>
									<IconInvest boxSize='15px' mr='5px' />
									{t('projectDetails.offerDetails.buyNow')}
								</Button>
							</>
						)}
						{!buyMode && isLoged && (
							<>
								{errMsg && (
									<Text
										fontSize='11px'
										textColor='negative'
										textAlign='center'
									>
										{errMsg}
									</Text>
								)}
								{valueInput && errMsg ? (
									<Button
										variant='main'
										fontSize='13'
										fontWeight='500'
										mb='5px'
										onClick={openModal}
									>
										<IconWallet boxSize='15px' mr='5px' />
										{t('projectDetails.offerDetails.topUp')}
									</Button>
								) : (
									<Button
										variant='main'
										fontSize='13'
										fontWeight='500'
										mb='5px'
										onClick={onSubmit}
									>
										<IconInvest boxSize='15px' mr='5px' />
										{t(
											'projectDetails.offerDetails.buyNow',
										)}
									</Button>
								)}
							</>
						)}
						{buyMode && (
							<Flex gap='12px'>
								<Button
									variant='secondary'
									fontSize='13'
									fontWeight='500'
									onClick={() => setBuyMode(false)}
								>
									<IconClose boxSize='14px' mr='5px' />
									{t('projectDetails.offerDetails.cancel')}
								</Button>
								<Button
									variant='main'
									fontSize='13'
									fontWeight='500'
									onClick={() => setSuccessfulBuy(true)}
								>
									<IconCheck boxSize='16px' mr='5px' />
									{t('projectDetails.offerDetails.confirm')}
								</Button>
							</Flex>
						)}
					</Flex>
				</CardBoxComponent>
			)}
			{modalLoginOpen && (
				<ModalComponent
					onClose={closeLoginModal}
					isOpen={modalLoginOpen}
					modalCross={false}
				>
					<LoginComponent isModal={true} />
				</ModalComponent>
			)}
			{successfulBuy && (
				<SuccessfulComponent
					p='20px'
					w='356px'
					h='930px'
					text={`${t('projectDetails.offerDetails.succesful.messagePart1')} [xxxxx] ${t('projectDetails.offerDetails.succesful.messagePart2')}
				[xxxxxxxxxxxxxxxxxxx]`}
					buttonTitle={t(
						'projectDetails.offerDetails.succesful.button',
					)}
				/>
			)}
			<AddCredit isOpen={isOpen} closeModal={closeModal} />
		</>
	);
};
export default OfferDetails;
