import { LoadScript } from '@react-google-maps/api';
import GoogleAutocomplete from './GoogleAutocomplete';

const AddressAutocomplete = ({
	selectedSuggestion,
	setSelectedSuggestion,
	incorrectData,
}) => {
	return (
		<LoadScript
			googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
			libraries={['places']}
		>
			<GoogleAutocomplete
				selectedSuggestion={selectedSuggestion}
				setSelectedSuggestion={setSelectedSuggestion}
				incorrectData={incorrectData}
			/>
		</LoadScript>
	);
};

export default AddressAutocomplete;
