/**
 * Extracts country, city, and address from address_components.
 * @param {Array} addressComponents - Array of address_components from Google Places API.
 * @returns {Object} - Object containing country, city, and address.
 */

const extractAddressInfo = addressComponents => {
	let country = '';
	let city = '';
	let streetAddress = '';
	let streetNumber = '';
	let postalCode = '';

	addressComponents.forEach(component => {
		const types = component.types;

		if (types.includes('country')) {
			country = component.long_name;
		} else if (types.includes('locality')) {
			city = component.long_name;
		} else if (
			types.includes('street_address') ||
			types.includes('route')
		) {
			streetAddress = component.long_name;
		} else if (types.includes('street_number')) {
			streetNumber = component.long_name;
		} else if (types.includes('postal_code')) {
			postalCode = component.long_name;
		}
	});

	return {
		country,
		city,
		streetAddress,
		streetNumber,
		postalCode,
	};
};

/**
 * Extracts latitude and longitude from geometry.
 * @param {Object} geometry - Geometry object from Google Places API.
 * @returns {Object} - Object containing latitude and longitude.
 */

const extractGeometryInfo = geometry => {
	if (geometry && geometry.location) {
		return {
			latitude: geometry.location.lat(),
			longitude: geometry.location.lng(),
		};
	} else {
		return {
			latitude: null,
			longitude: null,
		};
	}
};

/**
 * Retrieves place details from Google Places API using the placeId.
 * @param {string} placeId - The place ID of the selected suggestion.
 * @returns {Promise<Object>} - Promise resolving to the place details.
 */

const getPlaceDetails = placeId => {
	return new Promise((resolve, reject) => {
		const service = new window.google.maps.places.PlacesService(
			document.createElement('div'),
		);
		service.getDetails(
			{
				placeId,
				fields: ['address_components', 'geometry', 'formatted_address'],
			},
			(place, status) => {
				if (
					status ===
						window.google.maps.places.PlacesServiceStatus.OK &&
					place
				) {
					resolve(place);
				} else {
					reject(
						new Error('Error al obtener los detalles del lugar.'),
					);
				}
			},
		);
	});
};

/**
 * Handles saving the selected suggestion by retrieving its details.
 * @param {Object} selectedSuggestion - The selected suggestion from the autocomplete.
 * @returns {Promise<void>} - Promise resolving when the place details have been saved.
 */

export const savePlaceDetails = async selectedSuggestion => {
	if (selectedSuggestion) {
		try {
			const placeDetails = await getPlaceDetails(
				selectedSuggestion.place_id,
			);

			// Extract address and geometry info
			const addressInfo = extractAddressInfo(
				placeDetails.address_components,
			);
			const geometryInfo = extractGeometryInfo(placeDetails.geometry);

			// Create an object with all the data
			const placeData = {
				...addressInfo,
				coordinates: {
					lat: geometryInfo.latitude,
					long: geometryInfo.longitude,
				},
				formattedAddress: placeDetails.formatted_address,
			};

			return placeData;
		} catch (error) {
			console.error('Error fetching place details:', error);
		}
	}
};
