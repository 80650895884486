import axios from 'axios';
import { decodeToken, encodeToken } from '../utils/TokenUtils';

export const addProperty = async propertyInfo => {
	try {
		const token = encodeToken({
			/* data: propertyInfo.data, */
			// Se puede dejar solo la línea de arriba ↑↑↑ pero tienen que estar configurados city, country, address, postalCode y coordinates
			data: {
				isDraft: propertyInfo.data.isDraft,
				address: propertyInfo.data.address,
				appreciation: propertyInfo.data.appreciation,
				areaM2: propertyInfo.data.areaM2,
				category: propertyInfo.data.category,
				city: propertyInfo.data.city,
				country: propertyInfo.data.country,
				coordinates: propertyInfo.data.coordinates,
				currentPropertyValue: propertyInfo.data.currentPropertyValue,
				description: propertyInfo.data.description,
				distributionFrequency: propertyInfo.data.distributionFrequency,
				estimatedAnnualIncomeFromRental:
					propertyInfo.data.estimatedAnnualIncomeFromRental,
				estimatedResalePrice: propertyInfo.data.estimatedResalePrice,
				featuredImage: propertyInfo.data.featuredImage,
				financingEnd: propertyInfo.data.financingEnd,
				financingStart: propertyInfo.data.financingStart,
				isKYCRequired: propertyInfo.data.isKYCRequired,
				minTicketPrice: propertyInfo.data.minTicketPrice,
				paybackPeriod: propertyInfo.data.paybackPeriod,
				pricePerToken: propertyInfo.data.pricePerToken,
				postedBy: propertyInfo.data.postedBy,
				postalCode: propertyInfo.data.postalCode,
				projectEnd: propertyInfo.data.projectEnd,
				projectStart: propertyInfo.data.projectStart,
				propertyStatus: propertyInfo.data.projectStatus,
				propertyType: propertyInfo.data.propertyType,
				purchasedOrPledge: propertyInfo.data.purchasedOrPledge,
				quantityOfTokens: propertyInfo.data.quantityOfTokens,
				renovationAndFitoutsCosts:
					propertyInfo.data.renovationAndFitoutsCosts,
				rentalTimeline: propertyInfo.data.rentalTimeline,
				subcategory: propertyInfo.data.subcategory,
				taxesAndOperatingCosts:
					propertyInfo.data.taxesAndOperatingCosts,
				title: propertyInfo.data.title,
				withdrawWalletAddress: propertyInfo.data.withdrawWalletAddress,
			},
			equipments: propertyInfo.equipments,
			projectMethodPayments: propertyInfo.projectMethodPayments,
			updates: propertyInfo.updates,
			faqs: propertyInfo.faqs,
			timeline: propertyInfo.timeline,
		});

		const formData = new FormData();
		formData.append('token', token);
		propertyInfo.gallery.forEach(media => {
			formData.append('mediaFiles', media.file);
		});

		const response = await axios.post('/project/create', formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});

		return { success: true, data: decodeToken(response.data.data) };
	} catch (error) {
		if (error.response.data.message) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};

export const getProperty = async (type = 'default') => {
	try {
		const response = await axios.get('/project/all-projects/admin');

		return { success: true, data: decodeToken(response.data.data) };
	} catch (error) {
		if (
			error.response &&
			error.response.data &&
			error.response.data.message
		) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};
