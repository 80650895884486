import { Box, Flex } from '@chakra-ui/react';
import { IconClose, IconPlay } from '../../../../assets/Icons';

const MediaComponent = ({ media, onRemove }) => {
	return (
		<Flex
			position='relative'
			boxSize='100px'
			borderRadius='5px'
			backgroundImage={`url(${media.url})`}
			backgroundSize='cover'
			backgroundPosition='center'
			backgroundRepeat='no-repeat'
			justify='end'
		>
			{!media.isImage && (
				<>
					<Box
						as='video'
						src={media.url}
						width='100%'
						height='100%'
						borderRadius='5px'
						objectFit='cover'
						muted
					/>

					<Flex
						position='absolute'
						top='40px'
						right='35px'
						backgroundColor='transparencyBlack.800'
						boxSize='25px'
						borderRadius='50%'
						align='center'
						justify='center'
						pl='2px'
					>
						<IconPlay
							style={{
								fontSize: '12px',
							}}
						/>
					</Flex>
				</>
			)}
			<Flex
				position='absolute'
				top='5px'
				right='5px'
				backgroundColor='transparencyWhite.800'
				boxSize='15px'
				borderRadius='50%'
				align='center'
				justify='center'
				cursor='pointer'
				onClick={onRemove}
				pl='1px'
			>
				<IconClose boxSize='10px' color='transparencyBlack.800' />
			</Flex>
		</Flex>
	);
};

export default MediaComponent;
