/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Checkbox,
	CheckboxGroup,
	Divider,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	Text,
} from '@chakra-ui/react';

import {
	distributionFrequencyOptions,
	KYCOptions,
} from '../../../../data/optionsData';
import FormControlCustomComponent from './FormControlCustomComponent';

import { useEffect, useState } from 'react';
import AddDocumentsComponent from './AddDocumentsComponent';
import InfoBoxesComponent from './InfoBoxesComponent/InfoBoxesComponent';

const FinancialDetailsComponent = ({
	propertyInfo,
	setPropertyInfo,
	incorrectData,
}) => {
	const initialFinancialDetailsTabInfo = {
		distributionFrequency: distributionFrequencyOptions[0],
		isKYCRequired: KYCOptions.Enable,

		projectMethodPayments: propertyInfo.projectMethodPayments,
	};

	const [financialDetailsTabInfo, setFinancialDetailsTabInfo] = useState(
		initialFinancialDetailsTabInfo,
	);

	useEffect(() => {
		setPropertyInfo(prevState => ({
			...prevState,
			data: {
				...prevState.data,
				distributionFrequency:
					financialDetailsTabInfo.distributionFrequency,
				isKYCRequired: financialDetailsTabInfo.isKYCRequired,
			},
			projectMethodPayments: {
				...financialDetailsTabInfo.projectMethodPayments,
			},
		}));
	}, [financialDetailsTabInfo]);

	const handleFinancialDetailsTab = event => {
		const { name, value, type } = event.target;

		const parsedValue = type === 'number' ? parseFloat(value) : value;

		setPropertyInfo(prevState => ({
			...prevState,
			data: {
				...prevState.data,
				[name]: parsedValue,
			},
		}));
	};

	const handleCheckboxChange = values => {
		setFinancialDetailsTabInfo(prevState => ({
			...prevState,
			projectMethodPayments: {
				eth: values.includes('eth'),
				usdc: values.includes('usdc'),
				usdt: values.includes('usdt'),
			},
		}));
	};
	const defaultCheckboxValues = Object.keys(
		financialDetailsTabInfo.projectMethodPayments,
	).filter(key => financialDetailsTabInfo.projectMethodPayments[key]);

	const purchased = propertyInfo.data.purchasedOrPledge === 'Purchased';
	const selectedCurrency = 'EUR';

	return (
		<Flex gap='20px'>
			<Grid templateColumns='repeat(12, 1fr)' gap='25px'>
				<Text
					variant='colored'
					gridColumn='span 12'
					fontSize='20px'
					fontWeight='700'
					letterSpacing={-0.02}
				>
					Financial parameters
				</Text>
				<FormControl gridColumn='span 6'>
					<FormLabel variant='propertyLabel'>
						Payments methods
					</FormLabel>
					<CheckboxGroup
						onChange={handleCheckboxChange}
						defaultValue={defaultCheckboxValues}
					>
						<Flex
							h='40px'
							bg='transparencyWhite.100'
							borderRadius='5px'
							justifyContent='space-evenly'
							gap='15px'
							fontSize='10px'
						>
							<Checkbox value='usdt'>
								<Text fontSize='13px' fontWeight='400'>
									USDT
								</Text>
							</Checkbox>
							<Checkbox value='usdc'>
								<Text fontSize='13px' fontWeight='400'>
									USDC
								</Text>
							</Checkbox>
							<Checkbox value='eth'>
								<Text fontSize='13px' fontWeight='400'>
									ETH
								</Text>
							</Checkbox>
						</Flex>
					</CheckboxGroup>
				</FormControl>

				<FormControlCustomComponent
					gridColumn='span 6'
					formLabel='KYC'
					tooltipLabel='algo'
					options={KYCOptions}
					tabInfo={financialDetailsTabInfo}
					setTabInfo={setFinancialDetailsTabInfo}
					name='isKYCRequired'
					incorrectData={incorrectData}
					type='number'
				/>
				<FormControlCustomComponent
					formLabel='Price per token'
					tooltipLabel='The price assigned to each individual token in the project. Users will purchase tokens at this price, representing their share in the project.'
					symbol='€'
					name='pricePerToken'
					placeholder=''
					onChange={handleFinancialDetailsTab}
					incorrectData={incorrectData}
					type='number'
				/>
				<FormControlCustomComponent
					formLabel='Quantity of Tokens'
					tooltipLabel='This refers to the total number of tokens available for purchase in the project. Each token represents a portion of the investment and its associated returns.'
					symbol=''
					name='quantityOfTokens'
					placeholder=''
					onChange={handleFinancialDetailsTab}
					incorrectData={incorrectData}
					type='number'
				/>
				<FormControlCustomComponent
					formLabel='Payback Period'
					tooltipLabel='It indicates the number of months users must wait to start receiving returns on their investment.'
					symbol='Months'
					name='paybackPeriod'
					placeholder=''
					onChange={handleFinancialDetailsTab}
					incorrectData={incorrectData}
					type='number'
				/>
				<FormControlCustomComponent
					gridColumn={!purchased ? 'span 6' : 'span 4'}
					formLabel='Min Ticket Price'
					tooltipLabel='por definir'
					symbol='€'
					name='minTicketPrice'
					placeholder=''
					onChange={handleFinancialDetailsTab}
					incorrectData={incorrectData}
					type='number'
				/>
				{propertyInfo.data.purchasedOrPledge === 'Purchased' && (
					<FormControlCustomComponent
						formLabel='Investment Threshold'
						tooltipLabel='por definir'
						symbol='%'
						name='investmentThreshold'
						placeholder=''
						onChange={handleFinancialDetailsTab}
						incorrectData={incorrectData}
						type='number'
					/>
				)}
				<FormControlCustomComponent
					gridColumn={!purchased ? 'span 6' : 'span 4'}
					formLabel='Distribution Frequency'
					tooltipLabel='This refers to how often returns or payments will be distributed to users. It outlines whether distributions will occur monthly, quarterly, annually, or at another specified interval. The frequency distribution sets the schedule for when users can expect to receive their returns.'
					options={distributionFrequencyOptions}
					tabInfo={financialDetailsTabInfo}
					setTabInfo={setFinancialDetailsTabInfo}
					name='distributionFrequency'
					incorrectData={incorrectData}
				/>
				<Divider gridColumn='span 12' border='1px' />
				<Text
					variant='colored'
					gridColumn='span 12'
					fontSize='20px'
					fontWeight='700'
					letterSpacing={-0.02}
				>
					Wallet
				</Text>
				<FormControlCustomComponent
					gridColumn='span 12'
					formLabel='Wallet address to withdraw funds'
					tooltipLabel='Por definir'
					name='withdrawWalletAddress'
					placeholder=''
					onChange={handleFinancialDetailsTab}
					incorrectData={incorrectData}
				/>
				<Text
					gridColumn='span 12'
					fontSize='12px'
					fontWeight='400'
					mt='-18px'
				>
					<Text as='span' variant='colored' fontSize='12px'>
						Disclaimer:
					</Text>{' '}
					Please ensure that the address below is correct. If you send
					to an incorrect address we will not be able to recover the
					project funds
				</Text>
				<Divider gridColumn='span 12' border='1px' />
				<Text
					variant='colored'
					gridColumn='span 12'
					fontSize='20px'
					fontWeight='700'
					letterSpacing={-0.02}
				>
					Project Cost Summary
				</Text>
				<FormControlCustomComponent
					formLabel='Current property value'
					tooltipLabel='It indicates the current market value of the property.'
					symbol='€'
					name='currentPropertyValue'
					placeholder=''
					onChange={handleFinancialDetailsTab}
					incorrectData={incorrectData}
					type='number'
				/>
				<FormControlCustomComponent
					formLabel='Taxes and operating costs'
					tooltipLabel="This includes all registration fees, property taxes, maintenance costs, and any additional fees that the Project Creator wishes to add. It covers the ongoing expenses necessary for the property's operation and compliance."
					symbol='€'
					name='taxesAndOperatingCosts'
					placeholder=''
					onChange={handleFinancialDetailsTab}
					incorrectData={incorrectData}
					type='number'
				/>
				<FormControlCustomComponent
					formLabel='Renovation and fitouts costs'
					tooltipLabel="These are the estimated costs associated with any renovations, improvements, or fitouts required for the property. These expenses are crucial for enhancing the property's value and generating higher returns."
					symbol='€'
					name='renovationAndFitoutsCosts'
					placeholder=''
					onChange={handleFinancialDetailsTab}
					incorrectData={incorrectData}
					type='number'
				/>
				<Divider gridColumn='span 12' border='1px' />
				<Text
					variant='colored'
					gridColumn='span 12'
					fontSize='20px'
					fontWeight='700'
					letterSpacing={-0.02}
				>
					Rental Income Overview
				</Text>
				<FormControlCustomComponent
					gridColumn='span 6'
					formLabel='Estimated Annual Net Income from Rental'
					tooltipLabel='The projected net income from rental activities on an annual basis, after deducting expenses such as maintenance, property management, and taxes'
					symbol='€'
					name='estimatedAnnualIncomeFromRental'
					placeholder=''
					onChange={handleFinancialDetailsTab}
					incorrectData={incorrectData}
					type='number'
				/>
				<FormControlCustomComponent
					gridColumn='span 6'
					formLabel='Rental Timeline'
					tooltipLabel='The estimated duration over which the property or asset will be rented out. It includes the period from the start of rental activities to the anticipated end date.'
					symbol='Months'
					name='rentalTimeline'
					placeholder=''
					onChange={handleFinancialDetailsTab}
					incorrectData={incorrectData}
					type='number'
				/>
				<Divider gridColumn='span 12' border='1px' />
				<Text
					variant='colored'
					gridColumn='span 12'
					fontSize='20px'
					fontWeight='700'
					letterSpacing={-0.02}
				>
					Capital Growth and Estimated Returns
				</Text>
				<FormControlCustomComponent
					gridColumn='span 6'
					formLabel='Estimated Resale Price'
					tooltipLabel='It indicates the projected resale price of the property or asset. This estimate reflects the potential value at which the property could be sold, contributing to your overall return.'
					symbol='€'
					name='estimatedResalePrice'
					placeholder=''
					onChange={handleFinancialDetailsTab}
					incorrectData={incorrectData}
					type='number'
				/>
				<FormControlCustomComponent
					gridColumn='span 6'
					formLabel='Appreciation'
					tooltipLabel='The expected increase in the value of the property annually. Can be estimated by averaging the market growth over the past 3 years in the project location.'
					name='appreciation'
					placeholder=''
					symbol='%'
					onChange={handleFinancialDetailsTab}
					incorrectData={incorrectData}
					type='number'
				/>
				<Divider gridColumn='span 12' border='1px' />
				<Box gridColumn='span 12'>
					<AddDocumentsComponent />
				</Box>
			</Grid>
			<InfoBoxesComponent
				propertyInfo={propertyInfo}
				selectedCurrency={selectedCurrency}
			/>
		</Flex>
	);
};

export default FinancialDetailsComponent;
